/*
 * Mobile Featured News Carousel
 */

import Flickity from 'flickity/dist/flickity.pkgd.js';

// Immediately invoked function execution
jQuery(document).ready(function($) {

    let flkty;
    // Set current window size
    let windowSize = window.innerWidth;
    let breakpoint = 599;

    // Get carousel element
    let elem = document.querySelector('.news-carousel');

    function initCarousel(){
         if(elem){
            let options = {
                cellAlign: 'center',
                contain: true,
                wrapAround: true,
                lazyLoad: 2,
                initialIndex: 1,
                on:{
                    ready: function(){
                        if(flkty) flkty.reloadCells();

                        window.setTimeout(function(){
                            placeArrows(flkty);

                            if(flkty.slides.length < 2){
                                $(flkty.element).find('.flickity-button').hide();
                            }

                        }, 200);

                        $(window).resize(function() {
                            placeArrows(flkty);
                        });
                    },
                    settle: function( index ){
                        let allSlides = $('.carousel--hero .carousel--slide:not(.is-selected)');
                        let slide = flkty.selectedElement;

                        for(let i = 0, length1 = allSlides.length; i < length1; i++){
                            let btn = $( allSlides[i] ).find('.carousel--slide-button');
                            btn.attr({
                                'aria-hidden': 'true',
                                'tabindex': -1
                            });
                        }

                        $(slide).find('.carousel--slide-button').attr({
                            'aria-hidden': 'false',
                            'tabindex': 0
                        });
                    }
                }
            }

            flkty = new Flickity( elem, options);
        }
    }

     function placeArrows(flkty){
        console.log('placeArrows')

        if(typeof flkty === 'object'){
            if(typeof flkty.element === 'object'){

                let carousel    = $(flkty.element);
                let slide       = carousel.find('.news--items.is-selected');
                let btns        = carousel.find('.flickity-prev-next-button');

                let iH = slide.find('img').outerHeight();
                let bH = btns.first().outerHeight();

                console.log(iH, bH, btns.length);

                btns.css('top', iH/2);
            }
        }
    }

    function destroyCarousel(){
        if(flkty){
            flkty.destroy()
        }
    }

    if(windowSize < breakpoint){
        initCarousel();
    }

    window.onresize = function(event) {
        windowSize = window.innerWidth;

        if(windowSize < breakpoint){
            initCarousel();
        }
        else{
            destroyCarousel();
        }
    };

});