/*
 * Hero Carousel
 */
import Flickity from 'flickity/dist/flickity.pkgd.js';

jQuery(document).ready(function($) {
    let flkty;

    // Get carousel element
    let elem = document.querySelector('.carousel--hero');

    if(elem){
        let options = {
            cellAlign: 'center',
            contain: true,
            wrapAround: true,
            lazyLoad: 2,
            on:{
                ready: function(){
                    console.log('Hero')
                    $.each($('.flickity-page-dots li'), function(index, val) {
                        $(val).html('<button aria-label="Page '+(index + 1)+'"><span>'+(index + 1)+'</span></button>');
                    });

                    window.setTimeout(function(){
                        placeArrows(flkty);

                        if(flkty.slides.length < 2){
                            $(flkty.element).find('.flickity-button').hide();
                        }

                    }, 200);

                    $(window).resize(function() {
                        placeArrows(flkty);
                    });
                },
                settle: function( index ){
                    let allSlides = $('.carousel--hero .carousel--slide:not(.is-selected)');
                    let slide = flkty.selectedElement;

                    for(let i = 0, length1 = allSlides.length; i < length1; i++){
                        let btn = $( allSlides[i] ).find('.carousel--slide-button');
                        btn.attr({
                            'aria-hidden': 'true',
                            'tabindex': -1
                        });
                    }

                    $(slide).find('.carousel--slide-button').attr({
                        'aria-hidden': 'false',
                        'tabindex': 0
                    });
                },
                lazyLoad: function(){
                     placeArrows(flkty);
                }
            }
        }

        flkty = new Flickity( elem, options);
    }

    function placeArrows(flkty){
        if(typeof flkty === 'object'){
            if(typeof flkty.element === 'object'){

                let carousel    = $(flkty.element);
                let slide       = carousel.find('.carousel--slide.is-selected');
                let btns        = carousel.find('.flickity-prev-next-button');

                let iH = slide.find('img').outerHeight();
                let bH = btns.first().outerHeight();

                btns.css('top', iH/2);
            }
        }
    }
    let dots = document.querySelector('.flickity-page-dots').addEventListener('keyup', function(e){
            if(e.keyCode == 13){
                let pageID = e.target.getAttribute('aria-label').replace('Page ', '');
                flkty.select( pageID - 1);
            }
            if(e.keyCode == 39){
                let nextEle = e.target;

                if(nextEle.parentNode.nextElementSibling){
                    nextEle.parentNode.nextElementSibling.childNodes[0].focus();
                }
            }
            if(e.keyCode == 37){
                let prevEle = e.target;

                if(prevEle.parentNode.previousElementSibling){
                    prevEle.parentNode.previousElementSibling.childNodes[0].focus();
                }
            }
        }
    );
});